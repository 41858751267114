import { useState, useEffect } from 'react';
import {loadExternatScript} from "./loadExternatScript";

/**
 *
 * @param {*} src
 * @param isNeedLoadScript
 */

export const useScript = (src, isNeedLoadScript = true) => {
    // Keeping track of script loaded and error state
    const [state, setState] = useState({
        loaded: false,
        error: false,
    });

    useEffect(
        () => {
            // when isNeedLoadScript is false we think that we already have script functionality
            // it's usefull when we load polyfills
            if (!isNeedLoadScript) {
                setState({
                    loaded: true,
                    error: false,
                });
                return;
            }
            loadExternatScript(src)
                .then(() => {
                    setState({
                        loaded: true,
                        error: false,
                    });
                })
                .catch(() => {
                    setState({
                        loaded: true,
                        error: true,
                    });
                });
        },
        [src, isNeedLoadScript], // Only re-run effect if script src changes
    );

    return [state.loaded, state.error];
};
