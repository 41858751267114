const cachedScripts = [];

export const INTL_POLYFILL_URL = `https://polyfill.io/v3/polyfill.min.js?flags=always&features=Intl.~locale.${
    navigator.languages[0]
}`;

export const loadIntlPolyfill = loadExternatScript.bind(null, INTL_POLYFILL_URL);

export function loadExternatScript(src) {
    return new Promise(function loadScript(resolve, reject) {
        // If cachedScripts array already includes src that means
        // that the script was already loaded, so no need to load again.
        if (cachedScripts.includes(src)) {
            return resolve();
        }
        cachedScripts.push(src);

        // Create script
        const script = document.createElement('script');
        script.src = src;
        script.async = true;

        // Script event listener callbacks for load and error
        function onScriptLoad() {
            cleanup();
            resolve();
        }

        function onScriptError() {
            // Remove from cachedScripts we can try loading again
            const index = cachedScripts.indexOf(src);
            if (index >= 0) cachedScripts.splice(index, 1);
            script.remove();
            cleanup();
            reject(new Error(`error when loading extenral script: ${src}`));
        }

        function cleanup() {
            // Remove event listeners on cleanup
            script.removeEventListener('load', onScriptLoad);
            script.removeEventListener('error', onScriptError);
        }

        script.addEventListener('load', onScriptLoad);
        script.addEventListener('error', onScriptError);

        // Add script to document body
        document.body.appendChild(script);
    }).catch((err) => console.log(err));
}
