import { useState } from 'react'
import { isLocalDevelopment } from "./isLocalDevelopment";

const useGrecaptcha = () => {
    const GRECAPTCHA_KEY_PROP_NAME = 'g-recaptcha-site-key';
    const GRECAPTCHA_RESPONSE_PROP_NAME = 'g-recaptcha-response';
    const GRECAPTCHA_KEY = isLocalDevelopment()
        ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
        : '6Lf5UI4fAAAAAEZGjDB8axZsSnV8bfBG39ljwNDE';

    const [grecaptchaResponse, setGrecaptchaToken] = useState();

    return {
        GRECAPTCHA_KEY,
        GRECAPTCHA_KEY_PROP_NAME,
        GRECAPTCHA_RESPONSE_PROP_NAME,
        grecaptchaResponse,
        setGrecaptchaToken
    }
}

export default useGrecaptcha;
