import React, {useState, useEffect} from 'react';
import './App.scss';
import Header from './components/Header'
import Description from "./components/Description";
import Form from "./components/Form";
import Advantages from "./components/Advantages";
import Modal from "./components/Modal";
import translations from './translations'
import {initAnalytics, AmplitudeWrapper} from './Analytics'
import Footer from "./components/Footer";

const LEGAL_DATA = {
    'getbill.info': {
        entity: 'Meetville',
        name: 'Avanta Inc.',
        address: '1470 Civic Court\n' +
            'Ste 309\n' +
            'Concord, CA 94520\n' +
            'United States',
        email: 'support@meetville.com',
        phoneNumber: '+1(201) 808-3130',
        tollFree: ''
    }
}

const COLOR_BY_HOST = {
}

initAnalytics();

function App() {
    const host = window.location.host;
    const legalDataByHost = LEGAL_DATA[host] || LEGAL_DATA['getbill.info'];
    const legalName = legalDataByHost.entity;

    useEffect(()=> {
        AmplitudeWrapper('billTicketOpen', {
            result: legalName,
        });
    }, []);

    const [isShowModal, toggleModal] = useState(false);
    const [language, setLanguage] = useState('eng');

    const handleChangeLanguage = (lang) => {
        setLanguage(lang);
    };

    const templateColor = COLOR_BY_HOST[host] || '#44C5B8';

    return (
        <div className="app">
            <Header
                language={language}
                cb={handleChangeLanguage}
            />
            <Description
                translations={translations[language]}
            />
            <Form
                toggleModal={toggleModal}
                translations={translations[language]}
                templateColor={templateColor}
            />
            <Advantages
                translations={translations[language]}
                templateColor={templateColor}
            />
            <Modal
                isShowModal={isShowModal}
                toggleModal={toggleModal}
                translations={translations[language]}
            />
            <Footer
                data={legalDataByHost}
                templateColor={templateColor}
            />
        </div>
    );
}

export default App;
