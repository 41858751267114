import {useEffect, useState} from "react";

const useUploadFile = () => {
    const [uploadedFile, setUploadedFile] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    useEffect(()=> {
        const dropRegion = document.getElementById('drop-region');
        const fakeInput = document.createElement('input');
        fakeInput.type = 'file';
        fakeInput.accept = 'application/!*';
        fakeInput.multiple = true;
        dropRegion.addEventListener('click', function () {
            if (isLoading) return;
            fakeInput.click();
        });

        fakeInput.addEventListener('change', function () {
            const files = fakeInput.files;
            handleFiles(files);
        });

        function preventDefault(e) {
            e.preventDefault();
            e.stopPropagation();
        }

        dropRegion.addEventListener('dragenter', (e) => {
            if (isLoading) return;
            preventDefault(e);
        }, false);
        dropRegion.addEventListener('dragleave', (e) => {
            if (isLoading) return;
            preventDefault(e);
        }, false);
        dropRegion.addEventListener('dragover', (e) => {
            if (isLoading) return;
            preventDefault(e);
        }, false);
        dropRegion.addEventListener('drop', (e) => {
            if (isLoading) return;
            preventDefault(e);
        }, false);

        function handleDrop(e) {
            const dt = e.dataTransfer,
                files = dt.files;

            if (files.length) {
                handleFiles(files);
            } else {
                // check for img
                const html = dt.getData('text/html'),
                    match = html && /\bsrc="?([^"\s]+)"?\s*!/.exec(html),
                    url = match && match[1];
                if (url) {
                    uploadImageFromURL(url);
                }
            }
            function uploadImageFromURL(url) {
                const img = new Image;
                const c = document.createElement('canvas');
                const ctx = c.getContext('2d');

                img.onload = function () {
                    c.width = this.naturalWidth;     // update canvas size to match image
                    c.height = this.naturalHeight;
                    ctx.drawImage(this, 0, 0);       // draw in image
                    c.toBlob(function (blob) {        // get content as PNG blob
                        // call our main function
                        handleFiles([blob]);

                    }, 'image/png');
                };
                img.onerror = function () {
                    alert('Error in uploading');
                };
                img.crossOrigin = '';              // if from different origin
                img.src = url;
            }
        }

        dropRegion.addEventListener('drop', handleDrop, false);

        function handleFiles(files) {
            for (let i = 0, len = files.length; i < len; i++) {
                if (validateImage(files[i])) {
                    dropRegion.classList.add('dropped');
                    setUploadedFile(files[i]);
                }
            }
        }

        function validateImage(file) {
            // check the type
            const validTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];
            if (validTypes.indexOf(file.type) === -1) {
                alert('Invalid File Type');
                return false;
            }

            // check the size
            const maxSizeInBytes = 10e6; // 10MB
            if (file.size > maxSizeInBytes) {
                alert('File too large');
                return false;
            }
            return true;
        }

        document.getElementById('remove_dropped_file').addEventListener('click', function (e) {
            e.stopPropagation();
            fakeInput.value = '';
            dropRegion.classList.remove('dropped');
            setUploadedFile('')
        });

    }, [])

    return {
        uploadedFile,
        setUploadedFile,
        setIsLoading,
        isLoading
    }
}

export default useUploadFile;
