const translations = {
    eng: {
        title: "Why was I billed by your company?",
        description: "If you have any billing-related issues, we will be happy to assist you. Please send us request using the form below and our Billing Support Team will be in touch to immediate help regarding your issue.",
        enterNameAndEmail: "Please enter your Name and Email",
        enterDateAndAmount: "Please enter Date and Amount of the transaction",
        required: "Required",
        amount: "Amount",
        firstSix: "First six and the last four digits of the card:",
        otherInfo: "Any other information",
        sendRequest: "Send Request",
        moneyBack: "Money Back Guarantee",
        canRequestRefund: "You can request a refund once you are not satisfied with our products.",
        safetyGuarantee: "Safety Guarantee",
        personalInfo: "Any personal information you provide is totally safe with us.",
        securityGuarantee: "Security Guarantee",
        allTransactions: "All the transactions are completely secure.",
        errorEmail: "Incorrect email",
        errorDate: "Date incorrect",
        errorAmount: "Enter the amount",
        errorCard: "Enter the first six and last four digits of the card",
        name: "Name",
        email: "Email",
        appAccepted: "Your application has been accepted!",
        weWillReply: "We will reply to your message via email within 24 hours. Please do not forget to check your spam folder",
        ok: "okay",
        tryAgain: "Please check internet connection and try again.",
        attachFile: "📎 Attach the screenshot of the transaction",
        dragAndDrop: "Drag and drop, or browse for a file  to attach.",
    },
    fra: {
        title: "Pourquoi ai-je été facturé par votre entreprise?",
        description: "Si vous avez un problème en lien avec une facturation, nous serons heureux de vous assister. Veuillez nous envoyer une demande en utilisant le formulaire ci-dessous et notre équipe de support dédiée aux problèmes de facturation vous contactera immédiatement pour vous aider à régler votre problème.",
        enterNameAndEmail: "Veuillez saisir votre nom et votre courriel",
        enterDateAndAmount: "Veuillez saisir la date et le montant de la transaction",
        required: "Nécessaire",
        amount: "Montant",
        firstSix: "Les six premiers et les quatre derniers chiffres de votre carte :",
        otherInfo: "Toute autre information",
        sendRequest: "Envoyer la demande",
        moneyBack: "Garantie de remboursement",
        canRequestRefund: "Vous pouvez demander un remboursement une fois si vous n’êtes pas satisfait de nos produits.",
        safetyGuarantee: "Sécurité garantie",
        personalInfo: "Nous traitons avec la plus grande sécurité toutes les données personnelles que vous nous fournissez.",
        securityGuarantee: "Sécurité garantie",
        allTransactions: "Toutes les transactions sont totalement sécurisées.",
        errorEmail: "Veuillez entrer une adresse e-mail valide",
        errorDate: "Date incorrecte",
        errorAmount: "Saisissez le montant",
        errorCard: "Saisissez les six premiers et les quatre derniers chiffres de la carte",
        name: "Nom",
        email: "E-mail",
        appAccepted: "Votre demande d'inscription a été acceptée!",
        weWillReply: "Nous répondrons à votre message par courriel dans les prochaines 24 heures. Veuillez ne pas oublier de consulter le dossier pourriel de votre boîte de réception.",
        ok: "D'accord !👌",
        tryAgain: "Veuillez vérifier votre connexion internet et réessayez.",
        attachFile: "📎 Joindre la capture d’écran de la transaction",
        dragAndDrop: "Glissez-déposez ou recherchez un fichier à joindre.",
    },
    esp: {
        title: "¿Por qué recibí una factura de su empresa?",
        description: "Si tienes algún problema relacionado con la facturación, estaremos encantados de ayudarte. Envíanos una solicitud utilizando el formulario a continuación y nuestro equipo de soporte de facturación se pondrá en contacto para brindar ayuda inmediata con respecto a tu problema.",
        enterNameAndEmail: "Ingresa tu nombre y correo electrónico",
        enterDateAndAmount: "Ingresa la fecha y el monto de la transacción",
        required: "Requerido",
        amount: "Monto",
        firstSix: "Los primeros seis y los últimos cuatro dígitos de la tarjeta:",
        otherInfo: "Alguna otra información",
        sendRequest: "Enviar solicitud",
        moneyBack: "Garantía de devolución del dinero",
        canRequestRefund: "Puedes solicitar un reembolso en caso de no estar satisfecho/a con nuestros productos.",
        safetyGuarantee: "Garantía de seguridad",
        personalInfo: "Cualquier información personal que proporciones está totalmente segura con nosotros.",
        securityGuarantee: "Garantía de seguridad",
        allTransactions: "Todas las transacciones son completamente seguras.",
        errorEmail: "Por favor, ingrese la dirección de email correcta.",
        errorDate: "Fecha incorrecta",
        errorAmount: "Ingrese la cantidad",
        errorCard: "Ingrese los primeros seis y últimos cuatro dígitos de la tarjeta",
        name: "Nombre",
        email: "Email",
        appAccepted: "¡Se ha aceptado su solicitud!",
        weWillReply: "Responderemos su mensaje por correo electrónico dentro de 24 horas. No olvide revisar su carpeta de spam",
        ok: "¡Vale!👌",
        tryAgain: "Por favor, compruebe la conexión a Internet y vuelva a intentarlo.",
        attachFile: "📎 Adjuntar la captura de pantalla de la transacción.",
        dragAndDrop: "Arrastre y suelte, o busque un archivo para adjuntar.",
    },
};

export default translations;
