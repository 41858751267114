import Amplitude from 'amplitude-js';

const isProduction = () => process.env.NODE_ENV !== 'development';

const consoleTrack =
    // eslint-disable-next-line
    process.env.NODE_ENV === 'development' ? (system, ...args) => console.log(`Analytic event track to ${system}: `, ...args) : () => {};

export const AmplitudeWrapper = (...args) => {
    consoleTrack('Amplitude', ...args);
    Amplitude.logEvent(...args);
};

export async function initAnalytics() {
    // Initialize statistic for application
    // From npm - should be initialized before react app render
    const amplitudeProdKey = 'f67436a33fd59f0b636775257c8db431';
    const amplitudeDevKey = 'ff3ec71496802ef63f64109ec5e68088';
    const amplitudeKey = isProduction() ? amplitudeProdKey : amplitudeDevKey;

    Amplitude.init(amplitudeKey, null, {
        includeReferrer: true,
        includeUtm: true,
        includeGclid: true,
        saveParamsReferrerOncePerSession: false,
    });

    Amplitude.setUserProperties({
        appName: 'getbill',
        appDomain: window.location.hostname,
        // eslint-disable-next-line
        deviceOS: `${Amplitude._instances.$default_instance._ua.os.name} ${
            // eslint-disable-next-line
            Amplitude._instances.$default_instance._ua.os.version
            }`,
    });
}
