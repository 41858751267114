import React, {useState} from "react";
import arrowIcon from '../assets/arrow.svg'
import engIcon from '../assets/country/en.svg'
import espIcon from '../assets/country/spain.svg'
import fraIcon from '../assets/country/france.svg'
import gbrIcon from '../assets/country/gbr.svg'
import eurIcon from '../assets/country/eur.svg'
import inrIcon from '../assets/country/inr.svg'

const Dropdown = ({lang = 'eng', showTitle = false, items = [], cb = () => {}, canSetTop = false}) => {
    const [isOpenDropDown, toggleDropDown] = useState(false);
    const [orientation, setOrientation] = useState('top');
    const [currentLang, setLang] = useState(lang);

    const handleChangeLang = (e) => {
        if (isOpenDropDown && !e.target.closest('.dropdown')) {
            return toggleDropDown(false)
        } else if (e.target.closest('.dropdown')) {
            toggleDropDown(!isOpenDropDown);
        }

        if (e.target.closest('.dropdown-item')) {
            setLang(e.target.closest('.dropdown-item').dataset['key']);
            cb(e.target.closest('.dropdown-item').dataset['key']);
        }

        toggleDropDown(!isOpenDropDown);
    };

    const handleCloseDropDown = (e) => {
        if (!e.target.closest('.dropdown')) {
            toggleDropDown(false)
        }
    };

    React.useEffect(() => {
        document.addEventListener('click', handleCloseDropDown);
        return () => {
            document.removeEventListener('click', handleCloseDropDown)
        }
    }, []);
    React.useEffect(() => {
        if (isOpenDropDown && canSetTop) {
            const isVisible = isElementInViewport(document.querySelector('.field-wrapper .dropdown ul'));
            if (!isVisible) {
                setOrientation(orientation === 'bottom' ? 'top' : 'bottom')
            }
        }
    }, [isOpenDropDown]);

    const langIcons = {
        eng: engIcon,
        esp: espIcon,
        fra: fraIcon,
        gbr: gbrIcon,
        eur: eurIcon,
        inr: inrIcon,
    };

    const value = items.find(item => item.img === currentLang).key;

    function isElementInViewport (el) {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    return (
        <div className={`dropdown ${isOpenDropDown ? 'active' : ''}`} onClick={handleChangeLang}>
            <div>
                <span>
                    <img src={langIcons[currentLang]} alt={currentLang}/>
                    {showTitle &&
                        <span className={'dropdown-title'}>{value}</span>
                    }
                </span>
                <img src={arrowIcon} alt={'arrow'}/>
            </div>
            <ul style={{[orientation]: '100%'}}>
                {items.map(item => (
                    <li key={item.key + item.img} data-key={item.img} className={`dropdown-item ${currentLang === item.img ? 'active' : ''}`}><img src={langIcons[item.img]} alt={item.key}/>{item.key}</li>
                ))}
            </ul>
        </div>
    )
}

export default Dropdown
