import React from 'react';
import {ReactComponent as MoneyBack} from '../assets/money-back.svg'
import {ReactComponent as Safety} from '../assets/safety.svg'
import {ReactComponent as Security} from '../assets/security.svg'

const Advantages = ({translations, templateColor = '#44C5B8'}) => {
    return (
        <div className="advantages" style={{"--svg-color": templateColor}}>
            <div>
                <MoneyBack/>
                <h3>{translations.moneyBack}</h3>
                <p>{translations.canRequestRefund}</p>
            </div>
            <div>
                <Safety/>
                <h3>{translations.safetyGuarantee}</h3>
                <p>{translations.personalInfo}</p>
            </div>
            <div>
                <Security/>
                <h3>{translations.securityGuarantee}</h3>
                <p>{translations.allTransactions}</p>
            </div>
        </div>
    )
};

export default Advantages
