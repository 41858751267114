import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useScript } from '../utils/useScript';

const URL = 'https://www.google.com/recaptcha/api.js?onload=onloadCallback';


const ReCapcha = ({ onSetValue, hasError, GRECAPTCHA_KEY, setGrecaptchaToken }) => {
    const recaptchaRef = useRef(null);
    const [loaded, error] = useScript(URL);

    // This hook is needed for the re-render "grecaptcha".
    // Since when we go out of the form and return "grecaptcha" you need to re-render again.
    useEffect(
        () => {
            if (loaded && !error) {
                window.onloadCallback = () => {
                    window.grecaptcha.render(recaptchaRef.current, {
                        sitekey: GRECAPTCHA_KEY,
                        callback: (token) => {
                            onSetValue(token !== '');
                            setGrecaptchaToken(token);
                        },
                    });
                };
            }
            if (loaded && !error && window.grecaptcha && window.grecaptcha.render) {
                window.onloadCallback();
            }
            return () => {
                window.onloadCallback = null;
            };
        },
        // eslint-disable-next-line
        [loaded, error],
    );

    if (!loaded) return null;

    return (
        <div ref={recaptchaRef} className={`ReCapchaWrapp ${hasError ? 'ReCapchaWrapp__error' : ''}`} />
    );
};

ReCapcha.propTypes = {
    onSetValue: PropTypes.func,
    setGrecaptchaToken: PropTypes.string,
    GRECAPTCHA_KEY: PropTypes.string,
};

export default ReCapcha;
