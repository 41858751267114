import React, {useState, useEffect} from 'react'
import Dropdown from "./Dropdown";
import { validateEmail, validateDate, validateAmount, validateCard } from '../utils/validators'
import useUploadFile from "../utils/useUploadFile";
import ReCapcha from "./ReCapcha";
import useGrecaptcha from "../utils/useGrecaptcha";

const Form = ({toggleModal, translations, templateColor = '#44C5B8'}) => {
    const items = [{img: 'eng', key: 'USD'}, {img: 'gbr', key: 'GBR'}, {img: 'eur', key: 'EUR'}, {img: 'inr', key: 'INR'}];
    const [currency, setCurrency] = useState('USD');
    const [reCapcha, setReCapcha] = useState(false);
    const {uploadedFile, setUploadedFile, setIsLoading, isLoading} = useUploadFile();
    const initialState = {
        name: '',
        email: '',
        date: '',
        amount: '',
        pan: '',
        comment: ''
    };

    const {
        GRECAPTCHA_KEY,
        GRECAPTCHA_KEY_PROP_NAME,
        GRECAPTCHA_RESPONSE_PROP_NAME,
        grecaptchaResponse,
        setGrecaptchaToken
    } = useGrecaptcha();

    const host = window.location.host.replace('stage.', '');

    const [state, setState] = useState(initialState);
    const [errorsState, setErrorsState] = useState([]);

    useEffect(() => {
        if (uploadedFile) {
            setErrorsState(errorsState.filter(item => item !== 'attachment'))
        }
    }, [uploadedFile])

    const handleSetReCapcha = (isValid) => {
        setReCapcha(isValid)
        if (isValid) {
            setErrorsState(errorsState => errorsState.filter(item => item !== 'reCapcha'))
        }
    }

    const onChange = (field) => {
        return function handleChange(e) {
            switch (field) {
                case 'email' : {
                    setState({
                        ...state,
                        [field]: e.target.value
                    });
                    setErrorsState([...errorsState.filter(item => item !== 'email')]);
                    break;
                }
                case 'name' : {
                    setState({
                        ...state,
                        [field]: e.target.value
                    });
                    setErrorsState([...errorsState.filter(item => item !== 'name')]);
                    break;
                }
                case 'comment' : {
                    setState({
                        ...state,
                        [field]: e.target.value
                    });
                    setErrorsState([...errorsState.filter(item => item !== 'comment')]);
                    break;
                }
                case 'date' : {
                    const value = validateDate(state.date, e.target.value)
                    if (value === undefined) {
                        break;
                    }
                    setState({
                        ...state,
                        [field]: value
                    });
                    setErrorsState([...errorsState.filter(item => item !== 'date')]);
                    break;
                }
                case 'amount' : {
                    const value = validateAmount(state.amount, e.target.value)
                    if (value === undefined) {
                        break;
                    }
                    setState({
                        ...state,
                        [field]: value
                    });
                    setErrorsState([...errorsState.filter(item => item !== 'amount')]);
                    break;
                }
                case 'pan' : {
                    const value = validateCard(state.pan, e.target.value)
                    if (value === undefined) {
                        break;
                    }
                    setState({
                        ...state,
                        [field]: value
                    });
                    setErrorsState([...errorsState.filter(item => item !== 'pan')]);
                    break;
                }
                default: {
                    setState({
                        ...state,
                        [field]: e.target.value
                    });
                    break;
                }
            }

        }
    };

    const onBlur = (field) => {
        return function handleBlur(e) {
            switch (field) {
                case 'email' : {
                    let value = [];
                    if (!validateEmail(state.email)) {
                        value = new Set([...errorsState, 'email']);
                    } else {
                        value = errorsState.filter(item => item !== 'email');
                    }
                    setErrorsState([...value]);

                    break;
                }
                case 'date' : {
                    let value = [];
                    if (state.date.replace(/[^0-9]+/g, '').length < 8) {
                        value = new Set([...errorsState, 'date']);
                    } else {
                        value = errorsState.filter(item => item !== 'date');
                    }
                    setErrorsState([...value]);
                    break;
                }
                case 'amount' : {
                    let value = [];
                    if (state.amount.length < 1) {
                        value = new Set([...errorsState, 'amount']);
                        setErrorsState([...value]);
                    } else {
                        value = errorsState.filter(item => item !== 'amount');
                    }
                    setErrorsState([...value]);
                    break;
                }
                case 'pan' : {
                    let value = [];

                    if (state.pan.replace(/ /g,'').length < 16) {
                        value = new Set([...errorsState, 'pan']);
                    } else {
                        value = errorsState.filter(item => item !== 'pan');
                    }
                    setErrorsState([...value]);
                    break;
                }
                default: {
                    break;
                }
            }

        }
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        const url = `https://${host}/feedback`;
        const data = {
            ...state,
            currency
        };

        let errors = [];

        Object.keys(data).forEach(item => {
            if (!data[item]) {
                errors.push(item)
            }
        });

        if (!uploadedFile) {
            errors.push('attachment')
        }
        if (!reCapcha) {
            errors.push('reCapcha')
        }
        setErrorsState(errors);

        if (errors.length || errorsState.length) return;
        setIsLoading(true);

        if (uploadedFile) {
            let fileLoadedPercent = 13;
            let id = setInterval(function() {
                fileLoadedPercent += 40;
                if (fileLoadedPercent >= 100) {
                    clearInterval(id);
                    document.getElementById('progress_bar').style.width = '100%';
                } else {
                    document.getElementById('progress_bar').style.width = fileLoadedPercent + '%';
                }
            }, 800);
        }
        const formData = new FormData();
        formData.append('attachment', uploadedFile);
        formData.append('name', data.name);
        formData.append('email', data.email);
        formData.append('date', data.date);
        formData.append('amount', data.amount);
        formData.append('pan', data.pan);
        formData.append('comment', data.comment);
        formData.append('currency', data.currency);
        formData.append(GRECAPTCHA_KEY_PROP_NAME, GRECAPTCHA_KEY);
        formData.append(GRECAPTCHA_RESPONSE_PROP_NAME, grecaptchaResponse);

        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            },
            body: formData
        }).then(res => res.json())
        .then(result => {
            if (result?.errors.length === 0) {
                setState(initialState);
                toggleModal(true);
            } else {
                alert(translations.tryAgain);
            }
            setIsLoading(false);
            setUploadedFile('');
        }).catch(err => {
            setIsLoading(false);
            setUploadedFile('');
            alert(translations.tryAgain);
        })
    };

    const hideDropwDowns = () => {
        document.querySelector('body').classList.add('hide_dropdown');
    };

    return (
        <form className="form" autoComplete="off">
            <h3>{translations.enterNameAndEmail}</h3>
            <label className={errorsState.includes('name') ? 'inputError' : ''}>
                <input type="text" placeholder={translations.name} value={state.name} onChange={onChange('name')} autoComplete="off"/>
            </label>
            <label className={errorsState.includes('email') ? 'inputError' : ''}>
                <input type="email" placeholder={translations.email} value={state.email} onChange={onChange('email')} onBlur={onBlur('email')} onFocus={hideDropwDowns} autoComplete="off"/>
                <span>{translations.required}</span>
                <span className="errorText">{translations.errorEmail}</span>
            </label>
            <h3>{translations.enterDateAndAmount}</h3>
            <label className={errorsState.includes('date') ? 'inputError' : ''}>
                <input type="text" placeholder={'DD/MM/YYYY'} value={state.date} onChange={onChange('date')} onBlur={onBlur('date')} autoComplete="off"/>
                <span>{translations.required}</span>
                <span className="errorText">{translations.errorDate}</span>
            </label>
            <div className='field-wrapper'>
                <label className={errorsState.includes('amount') ? 'inputError' : ''}>
                    <input type="text" placeholder={translations.amount} value={state.amount} onChange={onChange('amount')} onBlur={onBlur('amount')} autoComplete="off"/>
                    <span>{translations.required}</span>
                    <span className="errorText">{translations.errorAmount}</span>
                </label>
                <div className='dropdown-wrap'>
                    <Dropdown
                        showTitle={true}
                        items={items}
                        cb={setCurrency}
                        canSetTop={true}
                    />
                </div>
            </div>
            <h3>{translations.firstSix}</h3>
            <label className={errorsState.includes('pan') ? 'inputError' : ''}>
                <input type="text" placeholder={'0000 00XX XXXX 0000'} value={state.pan} onChange={onChange('pan')} onBlur={onBlur('pan')} autoComplete="off"/>
                <span>{translations.required}</span>
                <span className="errorText">{translations.errorCard}</span>
            </label>
            <textarea  className={errorsState.includes('comment') ? 'inputError' : ''} placeholder={translations.otherInfo}  value={state.comment} onChange={onChange('comment')} autoComplete="off"/>
            <div
                id="drop-region"
                className={`field_cv ${errorsState.includes('attachment') ? 'inputError' : ''} ${uploadedFile ? 'dropped' : ''} ${isLoading ? 'disabled' : ''}`}
            >
                <div id="progress_bar" className={!uploadedFile ? 'empty' : ''}/>
                <div className="title">{translations.attachFile}</div>
                <div className="subtitle">{translations.dragAndDrop}</div>
                <div className="dropped_file_message">
                    <span id="dropped_file_message">{uploadedFile?.name}</span>
                        <div id="remove_dropped_file" className={isLoading ? '' : 'active'}>
                            <svg width="8" height="8" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                                    fill="#fff"/>
                            </svg>
                        </div>
                </div>
            </div>
            <ReCapcha
                onSetValue={handleSetReCapcha}
                GRECAPTCHA_KEY={GRECAPTCHA_KEY}
                setGrecaptchaToken={setGrecaptchaToken}
                hasError={errorsState.includes('reCapcha')}
            />
            <button style={{backgroundColor: templateColor}} type={'submit'} onClick={handleSubmit}>{translations.sendRequest}</button>
        </form>
    )
};

export default Form
