import React from 'react'
import Dropdown from './Dropdown';

const Header = ({language, cb}) => {
    const items = [{img: 'eng', key: 'eng'}, {img: 'fra', key: 'fra'}, {img: 'esp', key: 'esp'}];
    const host = window.location.host.replace('stage.', '');
    return (
        <header className="header">
            <a href="/" className="logo">{host}</a>
            <Dropdown
                lang={language}
                items={items}
                cb={cb}
            />
        </header>
    )
};

export default Header
