import React from 'react'
import mcs from '../assets/cards/mcs.png';
import mc from '../assets/cards/mc.png';
import vi from '../assets/cards/vi.png';
import vv from '../assets/cards/vv.png';

const Footer = ({data, templateColor}) => {
    if (!data) return null;
    return (
        <footer className="footer" style={{"--svg-color": templateColor}}>
            {data.name && (<p className={'footer-name'}>{data.name}</p>)}
            {data.address && (<p className={'footer-address'}>{data.address}</p>)}
            {data.phoneNumber && (<a href={`tel:${data.phoneNumber}`} className={'footer-phoneNumber'}>{data.phoneNumber}</a>)}
            {data.tollFree && (<a href={`tel:${data.tollFree}`} className={'footer-phoneNumber'}>TollFree: {data.tollFree}</a>)}
            {data.email && (<a href={`mailto:${data.email}`} className={'footer-email'}>{data.email}</a>)}
            <div className={'footer-cards'}>
                <img src={mcs} alt=""/>
                <img src={mc} alt=""/>
                <img src={vi} alt=""/>
                <img src={vv} alt=""/>
            </div>
        </footer>
    )
};

export default Footer
